import { Blockchain } from "../types/Blockchain";
type ChainDict = Record<string, Blockchain>;

export const chainDict: ChainDict = {
  JUNE: {
    rpc: "https://api.socotra-testnet.network/ext/bc/JUNE/rpc",
    chainId: 101003,
    stream: "0x67b0e689f4B1aAcc11636Fd6fF3f627219502F6a",
    tokens: [
      {
        name: "Wrapped JUNE",
        address: "0xDce22197228e8Ae74bC9D07539A29Ea9F6DE372a",
        symbol: "wJUNE",
        decimals: 18,
        blockchain: "June",
      },
    ],
    explorer: "https://mcnexplorer.io/chain/2/",
    name: "JUNE Chain",
    subgraph: "https://subgraph.mcnstream.io/subgraphs/name/sablierSocotra",
    receiver: "0xA299FE43186B0A0ff69CfE26328055f128934585",
  },
};
