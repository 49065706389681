import { chainDict } from "../../../../config/Config";

// const actualTime = (new Date().getTime() / 1000).toFixed().toString();
// console.log(actualTime);, stopTime_gt: "${actualTime}"
export const receivingQuery = (account: `0x${string}`) => `
    query 
        {streams(where: 
            { recipient: 
                "${account}", 
                token_: {id: "${chainDict[
                  "JUNE"
                ].tokens[0].address.toLowerCase()}"}
            }) {
                id 
                startTime
                stopTime
                ratePerSecond
                recipient
                sender
                timestamp
                deposit
                token {
                    tokenId :id
                    name
                    decimals
                    symbol
                }
                withdrawals {
                    withdrawalsId: id
                    withdrawalsAmount:amount
                    withdrawalsTimestamp:timestamp
                }
                cancellation {
                    recipientBalance
                    cancellationTimestamp:timestamp
                    txhash
                }
            }
        }
    `;
