// ModalContent.tsx
import "./ModalContent.scss";

import React from "react";
import { Connector as WagmiConnector } from "wagmi";

import AddressType from "../../../../types/Address";
import Button from "../../../Utils/Button/Button";

interface ModalContentProps {
  connectors: WagmiConnector[];
  onConnect: (connector: WagmiConnector) => void;
  error: Error | null;
  address: AddressType;
  isLoading: boolean;
  pendingConnector: WagmiConnector<any, any> | undefined;
  isConnected: boolean;
  disconnect: () => void;
}

const ModalContent: React.FC<ModalContentProps> = ({
  connectors,
  onConnect,
  error,
  address,
  isLoading,
  pendingConnector,
  isConnected,
  disconnect,
}) => {
  return (
    <div className="modalContentConnector">
      {!isConnected &&
        connectors.map((connector) => (
          <div key={connector.id}>
            <button
              className={`connectorButton ${connector.id}`}
              disabled={!connector.ready}
              onClick={() => onConnect(connector)}
            >
              <div className={`connectorIcon ${connector.name}`}></div>
              {connector.name}
              {!connector.ready && " (unsupported)"}
              {isLoading &&
                connector.id === pendingConnector?.id &&
                " (connecting)"}
            </button>
          </div>
        ))}
      {isConnected && (
        <div>
          <button className="connectorButton" onClick={disconnect}>
            Log out
          </button>
        </div>
      )}
      {error && <div>Error: {error.message}</div>}
    </div>
  );
};

interface ModalFooterButtonProps {
  disconnect: () => void;
}

const ModalFooterButton: React.FC<ModalFooterButtonProps> = ({
  disconnect,
}) => {
  return (
    <Button
      className="modalFooterButton"
      onClick={disconnect}
      label="Log Out"
    />
  );
};

export default ModalContent;
export { ModalFooterButton };
