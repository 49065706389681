import toast from "react-hot-toast";

async function appFetch(route: string, options?: RequestInit) {
  try {
    const response = await fetch(route, {
      ...options,
      credentials: "include",
    });

    if (response.status === 429) {
      toast.error("Too many requests");
      return null;
    }

    const contentType = response.headers.get("content-type");
    if (response.status === 403) {
      toast.error("Unauthorized");
      throw new Error("Unauthorized");
    }
    if (response.ok) {
      if (contentType && contentType.indexOf("application/pdf") !== -1) {
        return await response.blob();
      } else {
        const responseData = await response.json();
        responseData.status = response.status;
        return responseData;
      }
    } else {
      const responseData = await response.json();
      responseData.status = response.status;

      if (response) console.error("Error message:", responseData);
      toast.error(responseData.message);
      return null;
    }
  } catch (error) {
    console.error(error);

    return error;
  }
}

export default appFetch;
