import "./Admin.scss";

import { useState } from "react";
import { useContractWrite } from "wagmi";

import Button from "../../components/Utils/Button/Button";
import ButtonSwitcher, {
  ButtonSwitcherValue,
} from "../../components/Utils/ButtonSwitcher/ButtonSwitcher";
import { StyledInputText } from "../../components/Utils/StyledInput/StyledInput";
import contractABI from "../../config/SoulBound.json";
import useUserStore from "../../stores/UserStore";

type Option = ButtonSwitcherValue;

const Admin = () => {
  const options: Option[] = [
    {
      id: "read",
      name: "read",
    },
    {
      id: "write",
      name: "write",
    },
    {
      id: "admin",
      name: "admin",
    },
  ];

  const [selectedOption, setSelectedOption] = useState<Option>(options[0]);
  const [addressTo, setAddressTo] = useState<string>("");
  const [lastSuccessfullTxHash, setLastSuccessfullTxHash] = useState<
    string | null
  >();
  const { hasAccess, role } = useUserStore();

  /* Prepare SafeMint */
  const {
    data: _dataSafeMint,
    isLoading: _isLoadingSafeMint,
    isSuccess: _isSuccessSafeMint,
    write: _safeMint,
  } = useContractWrite({
    address: "0x2CB179fFB9FaFb0fC83141bdE5E5Eaa58D182313",
    abi: contractABI,
    functionName: "safeMint",
    onSuccess(data) {
      setLastSuccessfullTxHash(data.hash);
    },
    onError() {
      setLastSuccessfullTxHash(null);
    },
  });

  /* Prepare grantRole */
  const {
    data: _dataGrantRole,
    isLoading: _isLoadingGrantRole,
    isSuccess: _isSuccessGrantRole,
    write: _grantRole,
  } = useContractWrite({
    address: "0x2CB179fFB9FaFb0fC83141bdE5E5Eaa58D182313",
    abi: contractABI,
    functionName: "grantRole",
    onSuccess(data) {
      setLastSuccessfullTxHash(data.hash);
    },
    onError() {
      setLastSuccessfullTxHash(null);
    },
  });

  /* Call correct method (depending on selected method) */
  function contractCall() {
    if (selectedOption.id !== "admin") {
      _safeMint({
        args: [addressTo, selectedOption.id],
      });
    } else {
      _grantRole({
        args: [
          "0xa49807205ce4d355092ef5a8a18f56e8913cf4a201fbe287825b095693c21775",
          addressTo,
        ],
      });
    }
  }

  function constructTxString(txId: string) {
    const explorerURL = "https://mcnexplorer.io";
    return `${explorerURL}/chain/ieJEHSN4hCt74dafPvZSwoe9Fa12fmF4Ze496V63VM2DgKtRJ/tx/${txId}`;
  }

  // Assigning prop to use relevant the data based on selected role
  const isLoading = (function () {
    if (selectedOption.id === "read" || selectedOption.id === "write") {
      return _isLoadingSafeMint;
    }
    if (selectedOption.id === "admin") {
      return _isLoadingGrantRole;
    }
    throw new Error("Unable to handle selected option.");
  })();

  return (
    <div className="root">
      {hasAccess("admin") && (
        <div className="adminPageDiv">
          <div className="title">Assign a Role</div>

          <ButtonSwitcher
            value={selectedOption}
            setValue={setSelectedOption}
            datas={options}
            className="adminButtonSwitcher"
          />
          <StyledInputText
            label="Address"
            value={addressTo}
            setValue={setAddressTo}
            className="toAddressInput"
          />
          <Button
            onClick={contractCall}
            label="Mint"
            className="submitBtn"
            isLoading={isLoading}
          />

          {lastSuccessfullTxHash && (
            <div className="transactionUrl">
              Last successful tx:{" "}
              <a
                href={constructTxString(lastSuccessfullTxHash)}
                target="_blank"
                rel="noreferrer"
              >
                {lastSuccessfullTxHash}
              </a>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Admin;
