import "./AddStakeholder.scss";

import React, { useState } from "react";
import toast from "react-hot-toast";

import Button from "../../../components/Utils/Button/Button";
import {
  StyledInputNumber,
  StyledInputText,
} from "../../../components/Utils/StyledInput/StyledInput";
import useLoadingStore from "../../../stores/LoadingStore";
import InvestorType from "../../../types/Investor";
import appFetch from "../../../Utils/Services/Fetch/appFetch";
const AddStakeholder = () => {
  const { loadingStates, setLoading } = useLoadingStore();
  const [investor, setInvestor] = useState<InvestorType>({
    investor_name: "",
    investor_first_name: "",
    investor_address: "",
    amount_june: 0,
    price_june: 0,
    investor_id: -1,
  });

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (
      !investor.investor_address ||
      !investor.amount_june ||
      !investor.investor_name ||
      !investor.investor_first_name
    ) {
      toast.error("Please fill all the fields");
      return;
    }
    setLoading("add", true);

    try {
      console.log(investor);

      const response = await appFetch("/api/stakeholder/addStakeholder", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(investor),
      });

      if (response) {
        console.log(response);
        toast.success(response.message);
      }
    } catch (error) {
      console.error("Error:", error);
    }
    setLoading("add", false);
  };
  return (
    <>
      <div className="title">Add an Stakeholder</div>

      <form className="form" onSubmit={handleSubmit}>
        <StyledInputText
          label="Name"
          value={investor.investor_name}
          setValue={(value) =>
            setInvestor({ ...investor, investor_name: value })
          }
        />

        <StyledInputText
          label="First Name"
          value={investor.investor_first_name}
          setValue={(value) =>
            setInvestor({ ...investor, investor_first_name: value })
          }
        />

        <StyledInputText
          label="EVM Address"
          value={investor.investor_address}
          setValue={(value) =>
            setInvestor({ ...investor, investor_address: value })
          }
        />

        <StyledInputNumber
          label="Amount in June"
          value={investor.amount_june}
          setValue={(value) => setInvestor({ ...investor, amount_june: value })}
        />
        <StyledInputNumber
          label="Price in dollars"
          value={investor.price_june ?? 0}
          setValue={(value) => setInvestor({ ...investor, price_june: value })}
        />

        <Button
          type="submit"
          label={"Add StakeHolder"}
          className="loginSubmitButton"
          isLoading={loadingStates.add}
        />
      </form>
    </>
  );
};
export default AddStakeholder;
