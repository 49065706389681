import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { Navigate, Outlet } from "react-router-dom";
import { useAccount } from "wagmi";

import Spinner from "../../../components/Utils/Spinner/Spinner";
import useUserStore from "../../../stores/UserStore";
import appFetch from "../Fetch/appFetch";

const PrivateRoutes = () => {
  const [cookies, setCookie, removeCookie] = useCookies();
  const { address, isConnected } = useAccount();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { setRole, setIsLogged } = useUserStore();

  useEffect(() => {
    const verifyToken = async () => {
      console.log("Verifying token");

      try {
        const response = await appFetch(`/api/auth/verifyToken`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Cookie: `token=${cookies["token"]}`,
          },
        });
        if (response.message === "Unauthorized") {
          setIsAuthenticated(false);
          setIsLoading(false);
          removeCookie("token", { path: "/" });
          removeCookie("role", { path: "/" });
          setIsLogged(false);
          return;
        }
        setRole(response.access);
        setIsAuthenticated(response.isAuthenticated);
      } catch (error) {
        console.log(error);
        setIsAuthenticated(false);
      } finally {
        setIsLoading(false);
      }
    };

    if (
      isConnected &&
      cookies["token"] &&
      cookies["token"].path !== "/" &&
      address
    ) {
      verifyToken();
    } else {
      setIsAuthenticated(false);
      setIsLoading(false);
    }
  }, [cookies, address]);

  if (isLoading) {
    return (
      <div className="root">
        <div className="walletConnectPromptDiv">
          <Spinner />
        </div>
      </div>
    );
  }

  return isAuthenticated ? <Outlet /> : <Navigate to="/login" />;
};

export default PrivateRoutes;
