export const JUNE = {
  id: 220001,
  chainId: 220001,
  name: "JUNE Chain",
  network: "JUNE Chain",
  nativeCurrency: {
    decimals: 18,
    name: "JUNE Chain",
    symbol: "JUNE",
  },
  rpcUrls: {
    public: { http: ["https://api.socotra-testnet.network/ext/bc/JUNE/rpc"] },
    default: {
      http: ["https://api.socotra-testnet.network/ext/bc/JUNE/rpc"],
    },
  },
  blockExplorers: {
    etherscan: {
      name: "MCNScan",
      url: "https://mcnscan.io/chain/NLp7mU4yqN9xfu3Yezc6Sq66xFx5E1bKaxsBZRBZ7N7FmKhb5/",
    },
    default: {
      name: "MCNScan",
      url: "https://mcnscan.io/chain/NLp7mU4yqN9xfu3Yezc6Sq66xFx5E1bKaxsBZRBZ7N7FmKhb5/",
    },
  },
  soulboundContracts: {
    write: "0x33b823dec9B4eF36A09B9F653c4AcdF46465e8C0",
  },
};
