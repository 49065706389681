/// <reference types="vite-plugin-svgr/client" />

import "./App.scss";

import { Toaster } from "react-hot-toast";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import { MetaMaskConnector } from "wagmi/connectors/metaMask";
import { WalletConnectConnector } from "wagmi/connectors/walletConnect";
import { publicProvider } from "wagmi/providers/public";

import Navbar from "./components/Navbar/Navbar";
import { JUNE } from "./config/JunesChains";
import Admin from "./pages/Admin/Admin";
import Home from "./pages/Home/Home";
import Login from "./pages/Login/Login";
import Streams from "./pages/Streams/Streams";
import PrivateRoutes from "./Utils/Services/PrivateRoutes/PrivateRoutes";

const projectId = "876025f9fafe4ffa81d0ef52bef60881";

const { chains, publicClient, webSocketPublicClient } = configureChains(
  [JUNE],
  [publicProvider()],
);

const wagmiClient = createConfig({
  autoConnect: true,
  connectors: [
    new MetaMaskConnector({ chains }),
    new WalletConnectConnector({
      chains,
      options: {
        projectId: projectId,
      },
    }),
  ],
  publicClient,
  webSocketPublicClient,
});

function App() {
  return (
    <div className="App">
      <WagmiConfig config={wagmiClient}>
        <Router>
          <header>
            <Navbar />
          </header>
          <Routes>
            <Route element={<PrivateRoutes />}>
              <Route path="/" element={<Home />} />
              <Route path="/admin" element={<Admin />} />
              <Route path="/streams" element={<Streams />} />
              {/* Autres routes protégées ici */}
            </Route>
            {/* Route pour la page de connexion */}
            <Route path="/login" element={<Login />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </Router>
        <Toaster position="bottom-right" reverseOrder={false} />
      </WagmiConfig>
    </div>
  );
}

export default App;
