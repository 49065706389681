// ModalContent.tsx
import "./ModalContent.scss";

import React from "react";

import Button from "../../../../components/Utils/Button/Button";
import { StyledInputNumber } from "../../../../components/Utils/StyledInput/StyledInput";
import InvestorType from "../../../../types/Investor";

interface ModalContentProps {
  investor: InvestorType;
  setInvestor: React.Dispatch<React.SetStateAction<InvestorType>>;
}

const ModalContent: React.FC<ModalContentProps> = ({
  investor,
  setInvestor,
}) => {
  return (
    <div className="modalContentUpdateStakeholder">
      <StyledInputNumber
        label="Amount in June"
        value={investor.amount_june}
        setValue={(value) => {
          setInvestor({ ...investor, amount_june: value });
        }}
      />
      <StyledInputNumber
        label="Price in dollars"
        value={investor.price_june ?? 0}
        setValue={(value) => {
          setInvestor({ ...investor, price_june: value });
        }}
      />
    </div>
  );
};

interface ModalFooterButtonProps {
  updateStakeholder: () => void;
  isLoading: boolean;
}

const ModalFooterButton: React.FC<ModalFooterButtonProps> = ({
  updateStakeholder,
  isLoading,
}) => {
  return (
    <Button
      className="modalFooterButton"
      onClick={updateStakeholder}
      label="Update Stakeholder"
      isLoading={isLoading}
    />
  );
};

export default ModalContent;
export { ModalFooterButton };
