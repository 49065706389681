import "./Home.scss";

import React, { useState } from "react";

import HeaderButtons from "../../components/Utils/HeaderButtons/HeaderButtons";
import useUserStore from "../../stores/UserStore";
import AddStakeholder from "./AddStakeholder/AddStakeholder";
import StakeholdersList from "./StakeholdersList/StakeholdersList";

interface HomeProps {}

const Home: React.FC<HomeProps> = () => {
  const { hasAccess, role } = useUserStore();
  const [activeButton, setActiveButton] = useState(
    role === "read" ? "List" : "Add",
  );
  console.log(role);
  const buttons =
    role === "admin"
      ? ["Add", "List"]
      : role === "write"
      ? ["Add", "List"]
      : ["List"];
  return (
    <div className="root">
      <HeaderButtons
        activeButton={activeButton}
        setActiveButton={setActiveButton}
        buttons={buttons}
      />
      <div className="homePageDiv">
        {activeButton === "Add" && hasAccess("write") && <AddStakeholder />}
        {activeButton === "List" && hasAccess("read") && <StakeholdersList />}
      </div>
    </div>
  );
};

export default Home;
