import "./Button.scss";

import React, { ReactNode } from "react";
import toast from "react-hot-toast";
import { FaSpinner } from "react-icons/fa";

type ButtonProps = {
  onClick?: () => void;
  type?: "button" | "submit" | "reset";
  className?: string;
  label?: string;
  icon?: ReactNode;
  isLoading?: boolean;
};

const Button: React.FC<ButtonProps> = ({
  onClick,
  className,
  label,
  icon,
  type = "button",
  isLoading,
}) => {
  const handleClick = () => {
    if (isLoading) {
      toast.error("Opération en cours");
      return;
    }
    if (onClick) onClick();
  };
  return (
    <button
      onClick={handleClick}
      className={`${className ?? ""} styledButton`}
      type={type}
    >
      {isLoading ? (
        <FaSpinner className="loaderButton" />
      ) : (
        <>
          {icon}
          {label}
        </>
      )}
    </button>
  );
};

export default Button;
