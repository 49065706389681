import "./StakeholdersList.scss";

import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { FiEdit2, FiTrash2 } from "react-icons/fi";

import Button from "../../../components/Utils/Button/Button";
import Modal from "../../../components/Utils/Modal/Modal";
import Spinner from "../../../components/Utils/Spinner/Spinner";
import { StyledInputText } from "../../../components/Utils/StyledInput/StyledInput";
import useLoadingStore from "../../../stores/LoadingStore";
import useUserStore from "../../../stores/UserStore";
import InvestorType from "../../../types/Investor";
import appFetch from "../../../Utils/Services/Fetch/appFetch";
import ModalContent, { ModalFooterButton } from "./ModalContent/ModalContent";
const StakeholdersList = () => {
  const [stakeholdersList, setStakeholdersList] = useState<InvestorType[]>();
  const [stakeHolderToEdit, setStakeHolderToEdit] = useState<InvestorType>({
    investor_name: "",
    investor_first_name: "",
    investor_address: "",
    amount_june: 0,
    price_june: 0,
    investor_id: -1,
  });

  const { loadingStates, setLoading } = useLoadingStore();
  const { role } = useUserStore();
  const [search, setSearch] = useState("");

  const filteredStakeholders = stakeholdersList?.filter((stakeholder) => {
    return (
      stakeholder.investor_address
        .toLowerCase()
        .includes(search.toLowerCase()) ||
      stakeholder.investor_name.toString().includes(search.toLowerCase()) ||
      stakeholder.investor_first_name
        .toString()
        .includes(search.toLowerCase()) ||
      stakeholder.amount_june.toString().includes(search.toLowerCase())
    );
  });

  const fetchData = async () => {
    try {
      const response = await appFetch("/api/stakeholder/getStakeholdersList", {
        method: "GET",
      });

      if (response) {
        setStakeholdersList(response.stakeholder);
        setLoading("fetch", false);
      }
    } catch (error) {
      console.error("Error:", error);
      setLoading("fetch", false);
    }
  };
  useEffect(() => {
    setLoading("fetch", true);
    fetchData();
  }, []);

  const deleteStakeholder = async (investor_id: number) => {
    setLoading("delete" + investor_id, true);
    const response = await appFetch(`/api/stakeholder/deleteStakeholder`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ investor_id: investor_id }),
    });

    if (response && response.error) {
      toast.error(response.message);
    } else if (response && response.message) {
      toast.success(response.message);
      fetchData();
    }
    setLoading("delete" + investor_id, false);
  };

  const updateStakeholder = async () => {
    if (!stakeHolderToEdit) {
      toast.error("Please fill all the fields");
      return;
    }
    if (
      !stakeHolderToEdit?.investor_address ||
      !stakeHolderToEdit?.amount_june
    ) {
      toast.error("Please fill all the fields");
      return;
    }
    setLoading("update", true);

    try {
      const response = await appFetch("/api/stakeholder/updateStakeholder", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(stakeHolderToEdit),
      });

      if (response) {
        console.log(response);
        toast.success(response.message);
        fetchData();
      }
    } catch (error) {
      console.error("Error:", error);
    }
    setLoading("update", false);
  };

  return (
    <div className="stakeHolderList">
      <div className="title">Stakeholders list</div>
      {loadingStates.fetch ? (
        <Spinner />
      ) : (
        <div className="tableContainer">
          <StyledInputText
            label="Search"
            value={search}
            setValue={setSearch}
            className="searchInput"
          />
          <table className="table">
            <thead>
              <tr>
                <th>First Name</th>
                <th>Last Name</th>
                <th>EVM Address</th>
                <th>Create at</th>
                <th>Amount</th>
                <th>Amount ($)</th>
                {role !== "read" && <th>Actions</th>}
              </tr>
            </thead>
            <tbody>
              {filteredStakeholders &&
                filteredStakeholders.map((stakeholder) => (
                  <tr key={stakeholder.investor_id}>
                    <td>{stakeholder.investor_first_name}</td>
                    <td>{stakeholder.investor_name}</td>
                    <td>{stakeholder.investor_address}</td>
                    <td>
                      {new Date(
                        stakeholder.created_at ?? 0,
                      ).toLocaleDateString()}
                    </td>
                    <td>{Number(stakeholder.amount_june).toFixed(4)}</td>
                    <td>
                      {(
                        Number(stakeholder.amount_june) *
                        Number(stakeholder.price_june)
                      ).toFixed(2)}
                    </td>

                    {role !== "read" && (
                      <td>
                        {role === "admin" && (
                          <Button
                            className="tableButton"
                            onClick={() =>
                              deleteStakeholder(stakeholder.investor_id)
                            }
                            icon={<FiTrash2 />}
                            isLoading={
                              loadingStates["delete" + stakeholder.investor_id]
                            }
                          />
                        )}
                        <Modal
                          modalButton={
                            <Button
                              className="tableButton"
                              icon={<FiEdit2 />}
                              onClick={() => {
                                setStakeHolderToEdit(stakeholder);
                              }}
                            />
                          }
                          modalTitle="Update Stakeholder"
                          modalContent={
                            <ModalContent
                              investor={stakeHolderToEdit}
                              setInvestor={setStakeHolderToEdit}
                            />
                          }
                          modalFooterButton={
                            <ModalFooterButton
                              updateStakeholder={updateStakeholder}
                              isLoading={loadingStates.update}
                            />
                          }
                        />
                      </td>
                    )}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};
export default StakeholdersList;
