import { ApolloClient, DocumentNode, InMemoryCache } from "@apollo/client";

interface GetStreamsParams {
  uri: string; // The URI for the Apollo client
  query: DocumentNode; // The query to execute
  account: `0x${string}` | undefined;
}

async function getStreams({
  uri,
  query,
  account,
}: GetStreamsParams): Promise<any> {
  if (!account) {
    throw new Error("Account is null. Cannot proceed.");
  }

  // Create the Apollo client
  const client = new ApolloClient({
    uri: uri,
    cache: new InMemoryCache(),
  });

  try {
    // Execute the provided query
    const response = await client.query({
      query: query,
      variables: { account },
    });

    // Return the raw data
    return response.data;
  } catch (error) {
    console.error("An error occurred while fetching streams:", error);
    throw error;
  }
}

export { getStreams };
