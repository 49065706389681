// Connector.tsx
import React from "react";
import { useCookies } from "react-cookie";
import toast from "react-hot-toast";
import {
  Connector as WagmiConnector,
  useAccount,
  useConnect,
  useDisconnect,
  useSignMessage,
} from "wagmi";

import useUserStore from "../../../stores/UserStore";
import AddressType from "../../../types/Address";
import loginWithSignature, {
  getNonce,
} from "../../../Utils/Services/signMessage";
import Modal from "../../Utils/Modal/Modal";
import ModalContent from "./ModalContent/ModalContent";

const Connector: React.FC = () => {
  const { setIsLogged } = useUserStore();
  const { address, isConnected } = useAccount();
  const [cookies, removeCookie] = useCookies(["token", "role"]);
  const { disconnect } = useDisconnect({
    onSuccess(data) {
      document.getElementById("cancelModalButton")?.click();
      removeCookie("token", { path: "/" });
      removeCookie("role", { path: "/" });
      setIsLogged(false);
    },
  });
  const { signMessage } = useSignMessage({
    onSuccess(data) {
      if (address) {
        const fetchLoginWithSignature = async () => {
          setIsLogged(await loginWithSignature(address, data));
        };
        fetchLoginWithSignature();
      }
    },
    onError(error) {
      if (error.message.includes("User rejected the request")) {
        toast.error("User rejected the request");
      }
    },
  });

  const { connect, connectors, error, isLoading, pendingConnector } =
    useConnect({
      async onSuccess(data) {
        document.getElementById("cancelModalButton")?.click();
        removeCookie("token", { path: "/" });
        removeCookie("role", { path: "/" });
        const nonce = await getNonce(address);
        const message = `Login to Juneo App with nonce ${nonce}`;
        await signMessage({ message });
      },
    });

  const handleConnect = (connector: WagmiConnector) => {
    connect({ connector });
  };

  const modalContent = (
    <ModalContent
      connectors={connectors}
      onConnect={handleConnect}
      error={error}
      address={address}
      isLoading={isLoading}
      pendingConnector={pendingConnector}
      isConnected={isConnected}
      disconnect={disconnect}
    />
  );

  interface ModalButtonProps {
    address: AddressType;
  }

  const ModalButton: React.FC<ModalButtonProps> = ({ address }) => {
    return (
      <div className="nav-link">
        {address
          ? address.slice(0, 6) + "..." + address.slice(-4)
          : "Connect Wallet"}
      </div>
    );
  };

  return (
    <Modal
      modalButton={<ModalButton address={address} />}
      modalTitle="Connect Wallet"
      modalContent={modalContent}
    />
  );
};

export default Connector;
