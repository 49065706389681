import { create } from "zustand";

interface UserState {
  role: "read" | "write" | "admin" | undefined;
  setRole: (role: "read" | "write" | "admin" | undefined) => void;
  hasAccess: (requiredRole: "read" | "write" | "admin") => boolean;
  isLogged: boolean;
  setIsLogged: (isLogged: boolean) => void;
}

const useUserStore = create<UserState>((set, get) => ({
  role: undefined,
  setRole: (role) => set({ role }),
  hasAccess: (requiredRole: "read" | "write" | "admin") => {
    const currentRole = get().role;

    if (!currentRole) return false;

    switch (requiredRole) {
      case "read":
        return (
          currentRole === "read" ||
          currentRole === "admin" ||
          currentRole === "write"
        );
      case "write":
        return currentRole === "write" || currentRole === "admin";
      case "admin":
        return currentRole === "admin";
      default:
        return false;
    }
  },
  isLogged: false,
  setIsLogged: (isLogged) => set({ isLogged }),
}));

export default useUserStore;
