import "./Streams.scss";

import { gql } from "@apollo/client";
import { ethers } from "ethers";
import { useEffect, useState } from "react";

import Spinner from "../../components/Utils/Spinner/Spinner";
import { StyledInputText } from "../../components/Utils/StyledInput/StyledInput";
import { chainDict } from "../../config/Config";
import useUserStore from "../../stores/UserStore";
import Stream from "../../types/Stream";
import { getStreams } from "../../Utils/Services/Fetch/graphFetch";
import { receivingQuery } from "../../Utils/Services/Fetch/Query/receivingQuery";

const Streams = () => {
  const { hasAccess, role } = useUserStore();
  const [incomeStreams, setIncomeStreams] = useState<Stream[] | null>(null);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");

  // this function is used to create a client for each blockchain selected in the dropdown menu
  async function buildClient() {
    setIncomeStreams(null);
    setLoading(true);

    const incomeData = await getStreams({
      uri: chainDict["JUNE"].subgraph,
      query: gql(receivingQuery(chainDict["JUNE"].receiver)),
      account: chainDict["JUNE"].receiver,
    });
    console.log(incomeData);
    setIncomeStreams(incomeData.streams);

    setLoading(false);
  }

  useEffect(() => {
    buildClient();
  }, []);

  const filteredStreams = incomeStreams?.filter((stream) => {
    return (
      stream.id.toLowerCase().includes(search.toLowerCase()) ||
      stream.sender?.toLowerCase().includes(search.toLowerCase()) ||
      stream.deposit?.toString().includes(search.toLowerCase())
    );
  });

  return (
    <div className="root">
      <div className="streamsPageDiv">
        <div className="title">Streams</div>
        {loading && !incomeStreams ? (
          <Spinner />
        ) : (
          <div className="tableContainer">
            <StyledInputText
              label="Search"
              value={search}
              setValue={setSearch}
              className="searchInput"
            />
            <table className="table">
              <thead>
                <tr>
                  <th>Stream ID</th>
                  <th>Sender</th>
                  <th>Amount</th>
                  <th>Start Time</th>
                  <th>Stop Time</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {filteredStreams?.map((stream) => (
                  <tr key={stream.id}>
                    <td>{stream.id}</td>
                    <td>{stream.sender}</td>
                    <td>{ethers.formatEther(stream.deposit ?? 0)}</td>
                    <td>
                      {stream.startTime && (
                        <>
                          {new Date(
                            parseInt(stream.startTime.toString()) * 1000,
                          ).toLocaleDateString()}
                        </>
                      )}
                    </td>
                    <td>
                      {stream.stopTime && (
                        <>
                          {new Date(
                            parseInt(stream.stopTime.toString()) * 1000,
                          ).toLocaleDateString()}
                        </>
                      )}
                    </td>
                    {/* bublez green or red */}
                    <td>
                      {stream.stopTime &&
                      parseInt(stream.stopTime.toString()) * 1000 <
                        new Date().getTime() ? (
                        <div className="status">Completed</div>
                      ) : (
                        <div className="status">Active</div>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default Streams;
