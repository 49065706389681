import { toast } from "react-hot-toast";

import AddressType from "../../types/Address";
import appFetch from "./Fetch/appFetch";

export async function getNonce(address: AddressType) {
  try {
    const response = await appFetch(`/api/auth/getNonce?address=${address}`, {
      method: "GET",
    });
    if (response) {
      return response.nonce;
    }
  } catch (error) {
    console.log(error);
    toast.error("Error while getting nonce");
    throw error;
  }
}

export default async function loginWithSignature(
  address: AddressType,
  signature: string,
): Promise<boolean> {
  try {
    if (address && signature) {
      const response = await appFetch(`/api/auth/login`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ address, signature }),
      });

      if (response.status === 201) {
        return true;
      } else {
        return false;
      }
    }
  } catch (error) {
    return false;
  }
  return false;
}
