import "./Login.scss";

import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import {
  Connector as WagmiConnector,
  useAccount,
  useConnect,
  useDisconnect,
  useSignMessage,
} from "wagmi";

import ModalContent, {
  ModalFooterButton,
} from "../../components/Navbar/Connector/ModalContent/ModalContent";
import Button from "../../components/Utils/Button/Button";
import Modal from "../../components/Utils/Modal/Modal";
import useUserStore from "../../stores/UserStore";
import AddressType from "../../types/Address";
import loginWithSignature, { getNonce } from "../../Utils/Services/signMessage";
const Login = () => {
  const { role, setRole, isLogged, setIsLogged } = useUserStore();
  const [cookies, removeCookie] = useCookies();
  const [nonce, setNonce] = useState<number | undefined>(undefined);
  const { address, isConnected } = useAccount();
  const { connect, connectors, error, isLoading, pendingConnector } =
    useConnect({
      async onSuccess(data) {
        document.getElementById("cancelModalButton")?.click();
        removeCookie("token", { path: "/" });
        removeCookie("role", { path: "/" });
        if (address) {
          const nonce = await getNonce(address);
          setNonce(nonce);

          const message = `Login to Juneo App with nonce ${nonce}`;
          await signMessage({ message });
        }
      },
    });
  const { disconnect } = useDisconnect();
  const navigate = useNavigate();

  const { signMessage } = useSignMessage({
    onSuccess(data) {
      if (address) {
        const fetchLoginWithSignature = async () => {
          setIsLogged(await loginWithSignature(address, data));
        };
        fetchLoginWithSignature();
      }
    },
    onError(error) {
      if (error.message.includes("User rejected the request")) {
        toast.error("User rejected the request");
      }
    },
  });

  useEffect(() => {
    async function fetchData() {
      const nonce = await getNonce(address);
      setNonce(nonce);
    }
    if (!nonce && address) {
      fetchData();
    }
  }, [nonce, address]);

  useEffect(() => {
    if (isLogged) {
      navigate(`/`);
    }
  }, [isLogged]);

  const handleConnect = (connector: WagmiConnector) => {
    connect({ connector });
  };

  const modalContent = (
    <ModalContent
      connectors={connectors}
      onConnect={handleConnect}
      error={error}
      address={address}
      isLoading={isLoading}
      pendingConnector={pendingConnector}
      isConnected={isConnected}
      disconnect={disconnect}
    />
  );
  const modalFooterButton = <ModalFooterButton disconnect={disconnect} />;

  interface ModalButtonProps {
    address: AddressType;
  }

  const ModalButton: React.FC<ModalButtonProps> = ({ address }) => {
    return (
      <Button
        label={
          address
            ? address.slice(0, 6) + "..." + address.slice(-4)
            : "Connect Wallet"
        }
      />
    );
  };

  if (!isConnected) {
    return (
      <div className="root">
        <div className="walletConnectPromptDiv">
          Connect your wallet
          <Modal
            modalButton={<ModalButton address={address} />}
            modalTitle="Connect Wallet"
            modalContent={modalContent}
            modalFooterButton={modalFooterButton}
          />
        </div>
      </div>
    );
  } else {
    return (
      <div className="root">
        <div className="walletConnectPromptDiv">
          Sign the message to prove your identity
          {nonce && (
            <Button
              onClick={() => {
                signMessage({
                  message: `Login to Juneo App with nonce ${nonce}`,
                });
              }}
              label="Sign message"
            />
          )}
        </div>
      </div>
    );
  }
};

export default Login;
